import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Menu = props => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <li>
          <Link onClick={props.onToggleMenu} to="/">
            Home
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/the-process">
            The Process
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/artist-dmon">
            D'mon
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/artist-emm">
            Emm
          </Link>
        </li>

        <li>
          <Link onClick={props.onToggleMenu} to="/artist-tony">
            Tony
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/artist-jeanine">
            Jeanine
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/artist-christos">
            Christos
          </Link>
        </li>
      </ul>
      <ul className="actions vertical">
        <li>
          <a
            href="#contact"
            onClick={props.onToggleMenu}
            className="button special fit"
          >
            Booking
          </a>
        </li>
      </ul>
    </div>
    <a className="close" onClick={props.onToggleMenu} href="javascript:;">
      Close
    </a>
  </nav>
);

Menu.propTypes = {
  onToggleMenu: PropTypes.func
};

export default Menu;

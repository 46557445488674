import React from "react";

const Contact = props => (
  <section id="contact">
    <div className="inner">
      <section>
        <form
          name="obsidian"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          action="/success"
        >
          <input type="hidden" name="form-name" value="obsidian" />
          <div className="field half first">
            <label htmlFor="name">Name</label>
            <input type="text" name="name" id="name" />
          </div>
          <div className="field half">
            <label htmlFor="email">Email</label>
            <input type="text" name="email" id="email" />
          </div>
          <div className="field">
            <select name="category" id="category">
              <option defaultValue="">- Select Artist -</option>
              <option name="Dmon" value="Dmon">
                D'mon
              </option>
              <option name="Emm" value="Emm">
                Emm
              </option>
              <option name="Tony" value="Tony">
                Tony
              </option>
              <option name="Jeanine" value="Jeanine">
                Jeanine
              </option>
              <option name="Christos" value="Christos">
                Christos
              </option>
            </select>
          </div>
          <div className="field">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" rows="6"></textarea>
          </div>
          <div data-netlify-recaptcha="true"></div>
          <ul className="actions">
            <li>
              <input
                type="submit"
                name="submit"
                value="Send Message"
                className="special"
              />
            </li>
            <li>
              <input type="reset" value="Clear" />
            </li>
          </ul>
        </form>
      </section>
      <section className="split">
        <section>
          <div className="contact-method">
            <span className="icon alt fa-phone"></span>
            <h3>Phone</h3>
            <span>(323) 928-2892</span>
          </div>
        </section>
        <section>
          <div className="contact-method">
            <span className="icon alt fa-home"></span>
            <h3>Address</h3>
            <span>
              3321 West Sunset Boulevard Los Angeles, <br /> California 90026
            </span>
          </div>
          <div className="contact-method">
            <p>
              Obsidian LA Tattoo ranks among the top tattoo studios in the
              Silver Lake district of Los Angeles; we have a diverse group of
              artists with a wide range of unique tattoo styles. Our doors are
              open to anyone and everyone, and we can't wait to bring your
              vision to life.
            </p>
          </div>
          <h3>
            <a
              href="https://www.instagram.com/obsidianlosangeles/?hl=en"
              className="icon alt fa-instagram"
            >
              <span className="label">Instagram</span> &nbsp;
              @ObsidianLosAngeles
            </a>
          </h3>
        </section>
        <section>
          <div className="contact-method">
            <a
              href="/"
              onClick={props.onToggleMenu}
              className="button special fit"
            >
              Back home
            </a>
          </div>
        </section>
      </section>
    </div>
  </section>
);

export default Contact;

import React from "react";
import logo from "../assets/images/logo.svg";

const Banner = props => (
  <section id="banner" className="major">
    <div className="inner">
      <header className="major">
        <img src={logo} alt="obsidian los angeles tattoo" className="logo" />
      </header>
      <div className="content">
        <h1 className="primary-title">
          Obsidian Los Angeles Tattoo, 3321 West Sunset Boulevard Los Angeles,
          California 90026
        </h1>
        <ul className="actions">
          <li>
            <a href="#artists" className="button next scrolly artists">
              View Artists
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export default Banner;

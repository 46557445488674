import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Banner from "../components/Banner";
import SEO from "../components/Seo";

import dmon from "../assets/images/artists/dmon/profile/dmon-artist-profile-medium.jpg";
import emm from "../assets/images/artists/emm/profile/emm-artist-profile.jpg";
import sofia from "../assets/images/artists/sofia/profile/sofia-artist-profile.jpg";
import jeanine from "../assets/images/artists/jeanine/profile/jeanine-artist-profile.jpeg";
import christos from "../assets/images/artists/christos/profile/christos-artist-profile-medium.jpg";

class HomeIndex extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <Layout>
        <SEO
          title="Obsidian LA "
          description="Obsidian LA Tattoo studio, combining elegance and fine lines into a collaborative experience."
          keywords={[
            `tattoos in los angeles`,
            `los angeles tattoo artists`,
            `obsidian la tattoo`,
            `tattoos los angeles`,
            `la tattoo studios`
          ]}
        />
        <Banner />
        <div id="main">
          <section id="artists" className="tiles">
            <article style={{ backgroundImage: `url(${dmon})` }}>
              <header className="major">
                <h3>D'mon - The Architect</h3>
                <p>See work</p>
              </header>
              <Link to="/artist-dmon" className="link primary"></Link>
            </article>
            <article style={{ backgroundImage: `url(${emm})` }}>
              <header className="major">
                <h3>Emily Kay</h3>
                <p>See work</p>
              </header>
              <Link to="/artist-emm" className="link primary"></Link>
            </article>
            <article style={{ backgroundImage: `url(${jeanine})` }}>
              <header className="major">
                <h3>Jeanine</h3>
                <p>See work</p>
              </header>
              <Link to="/artist-jeanine" className="link primary"></Link>
            </article>
            <article style={{ backgroundImage: `url(${christos})` }}>
              <header className="major">
                <h3>Christos</h3>
                <p>See work</p>
              </header>
              <Link to="/artist-christos" className="link primary"></Link>
            </article>
          </section>
          <section id="two">
            <div className="inner">
              <header className="major">
                <h2>Latest Blog Posts</h2>
              </header>
              <h4>
                <Link to="/blog" className="next">
                  PROUD to be a Tattooer: Notes on Tattooing and the LGBT
                  Community
                </Link>
              </h4>
              <h5>by Rick Schenk &nbsp; </h5>
              <hr />

              <header className="major">
                <h2>Obsidian Los Angeles tattoo studio</h2>
              </header>
              <h4> A present movement of an old tradition ~ Tattoo. </h4>
              <p>
                Obsidian LA Tattoo was established in 2018 by renowned tattoo
                artist, D’mon AKA “The Architect.” Combining elegance and fine
                lines into a collaborative experience, every artist at the
                Obsidian LA Tattoo studio creates pieces that are unique to each
                client. Artistic expression paired with an open and uniquely
                designed space cultivates the perfect environment for concepts
                to become a reality. Obsidian Tattoo LA is loacted in the Silver
                Lake district of Los Angeles. Contact us using the form below or
                drop in for a consultation with the best tattoo artists in Los
                Angeles.
              </p>
              <ul className="actions">
                <li>
                  <Link to="/the-process" className="button next">
                    More info
                  </Link>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default HomeIndex;

import React from "react";

const Footer = props => (
  <footer id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a
            href="https://twitter.com/obsidianlosangeles"
            className="icon alt fa-twitter"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/obsidianlosangeles/?hl=en"
            className="icon alt fa-instagram"
          >
            <span className="label">Instagram</span>
          </a>
        </li>
      </ul>
      <ul className="copyright">
        <li>&copy; Obsidian Los Angeles Tattoo</li>
        <li>
          Developed by: <a href="http://github.com/amponce">AMP</a>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
